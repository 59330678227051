define(["siteObj", "cookie", "$window"], function (siteObj, cookie, $window) {
  const qubitProductPageBadging = () => {
    const component = {};
    const language = siteObj.siteDefaultLocale.replace(/_/g, "-");
    const siteUrl = $window.location.host;
    const qubitCookieName = '_qubitTracker';
    const viewWindowSize = 1440;
    let attempts = 0;
    const schemaTimeframes = {
      'in the last hour': 60,
      'in the last day': 1440,
      'in the last 2 days': 2880,
      'in the last 3 days': 4320,
      'in the last week': 10080
    }

    component.config = {
      selectors: {
        productName: '[data-product-name="title"]',
        productBadge: '.qubit-pdp-badging-container',
        productBadgingContainer: '[data-qubit-container]',
        productBadgingButton: '[data-product-badge="click"]'
      },
      urls: {
        qubitUrl: 'https://api.qubit.com/placements/query',
        qubitTallyUrl: 'https://tally-1.qubitproducts.com/tally',
        qubitTrackingUrl: 'https://api.qubit.com/placements/cb?'
      }
    };

    component.init = () => {
      component.productName = document.querySelector(component.config.selectors.productName).textContent;
      component.productPageplacemnetId = siteObj.config.qubitProductPageBadgingPlacementId;
      component.trackingId = siteObj.config.qubitTrackingId;

      try {
        if (
          cookie.get('actualOptanonConsent') && cookie.get('actualOptanonConsent').includes('C0002') ||
          $window.cookieName === 'actualOptanonConsent' && $window.cookieValue.includes('C0002')
        ) {
          if (component.productPageplacemnetId) {
            component.makeRequest(component.productName, component.productPageplacemnetId, component.trackingId);
          }
        } else {
          component.getCookieConsent();
        }
      } catch (error) {
        console.log('OneTrust not Found')
      }

    };

    component.getQubitQuery = (productName, placementId, getVisitorId, placementMode, getPreviewMode, urlPreviewParams) => {
      const qubitQuery = `query PlacementContent (
        $mode: Mode!
        $placementId: String!
        $previewOptions: PreviewOptions
        $attributes: Attributes!
        $resolveVisitorState: Boolean!
      ) {
        placementContent(
          mode: $mode
          placementId: $placementId
          previewOptions: $previewOptions
          attributes: $attributes
          resolveVisitorState: $resolveVisitorState
        ) {
          content
          callbackData
          visitorId
        }
      }`;

      const qubitVariables = {
        mode: placementMode,
        placementId: placementId,
        resolveVisitorState: true,
        attributes: {
          visitor: {
            id: getVisitorId,
            url: siteObj.siteURL,
          },
          user: {},
          product: {
            'id': siteObj.productID,
            'name': productName,
          },
          view: {
            currency: siteObj.currencyType,
            type: 'product',
            subtypes: [],
            language: language,
          },
        }
      }

      if (getPreviewMode) {
        const getQbcampaignId = urlPreviewParams.get('qb_campaign_id');
        const getQbgroup = urlPreviewParams.get('qb_group');
        const getQbexperienceId = urlPreviewParams.get('qb_experience_id');
        let previewOptions = { campaignId: getQbcampaignId, group: getQbgroup, experienceId: getQbexperienceId }
        qubitVariables['previewOptions'] = previewOptions;
      }

      return [qubitQuery, qubitVariables];
    }

    component.makeRequest = (productName, placementId, trackingId) => {
      let getVisitorId = '';
      let placementMode = 'LIVE';
      let queryString = $window.location.search;
      let queryHash = $window.location.hash;
      let queryParams = (queryString) ? queryString : queryHash;
      const urlPreviewParams = (queryParams) ? new URLSearchParams(queryParams) : null;
      const getPreviewMode = (urlPreviewParams) ? urlPreviewParams.get('qb_campaign_id') : null;
      const getSampleMode = (urlPreviewParams) ? urlPreviewParams.get('qb_mode') : null;

      if (getPreviewMode || getSampleMode) {
        let getQbplacementId = urlPreviewParams.get('qb_placement_id');
        placementId = getQbplacementId;

        if (getPreviewMode) {
          placementMode = 'PREVIEW';
        }
        if (getSampleMode) {
          placementMode = getSampleMode;
        }
      }

      if (cookie.get(qubitCookieName)) {
        getVisitorId = cookie.get(qubitCookieName);
      };

      let getQuery = component.getQubitQuery(productName, placementId, getVisitorId, placementMode, getPreviewMode, urlPreviewParams);

      component.getQubitData(getQuery[0], getQuery[1]).then((response) => {
        if (!response.data.placementContent) {
          return;
        }
        const productListPageplacemnetId = siteObj.config.qubitProductListPageBadgingPlacementId;
        const content = response.data.placementContent.content;
        const callbackData = response.data.placementContent.callbackData;
        const productBadgingContainer = document.querySelector(component.config.selectors.productBadgingContainer);
        const setVisitorId = response.data.placementContent.visitorId;

        component.setCookie(setVisitorId);
        component.impression(callbackData, productBadgingContainer);

        if (!siteObj.experiments.some(experiment => experiment.name === "qubit_pdp_badging" && experiment.value !== "v1|pdp_badging")) {
          // If we have content from a campaign
          if (content) {
            if (content.recs) {
              return;
            }
            component.createProductBadge(content, productBadgingContainer);
            component.closeProductBadge();
          }
          if (productListPageplacemnetId) {
            if (!getPreviewMode && !getSampleMode) {
              component.sendQubitData(productListPageplacemnetId, trackingId, siteObj.productID, getVisitorId, placementMode, getPreviewMode, urlPreviewParams)
            }
          }
        }
      });
    };

    // Get qubit product badging data
    component.getQubitData = (query, variables) => {
      return new Promise(resolve => {
        fetch(component.config.urls.qubitUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'text/plain' },
          body: JSON.stringify({
            query: query,
            variables: variables,
          }),
        })
          .then((res) => { return resolve(res.json()) })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    // Save qubit product view count
    component.sendQubitData = (productListPageplacemnetId, trackingId, productId, getVisitorId, placementMode, getPreviewMode, urlPreviewParams) => {
      const productName = document.querySelector(component.config.selectors.productName).textContent;
      let getQuery = component.getQubitQuery(productName, productListPageplacemnetId, getVisitorId, placementMode, getPreviewMode, urlPreviewParams);

      component.getQubitData(getQuery[0], getQuery[1]).then((response) => {
        const content = response.data.placementContent.content;
        if (content) {
          const counter = content.config.counter.toLowerCase();
          const timeframe = counter === 'views' ? viewWindowSize : schemaTimeframes[content.config.timeframe.toLowerCase()];

          return new Promise(resolve => {
            fetch(`${component.config.urls.qubitTallyUrl}/${trackingId}/ecount/atom-views-${timeframe}min/${productId}/${timeframe}`, {
              method: 'POST',
              headers: { 'Content-Type': 'text/plain' }
            })
              .then((res) => {
                if (res.ok) {
                  return resolve(res.json());
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
      });
    }

    // product badging markup
    component.createProductBadge = (content, productBadgingContainer) => {
      const markup = `
        <div class="qubit-pdp-badging-container visible">
          ${(content.imageUrl) ? `<img src="${content.imageUrl}" alt="${content.message}" class="qubit-pdp-img"/>` : ''}
          <span class="qubit-pdp-message">
              ${content.message}
          </span>
          <button data-product-badge="click" type="button">
            <svg class="responsiveFlyoutMenu_cross" width="24" height="24" viewBox="0 0 24 24">
              <path d="M12.0020447,10.5878311 L18.0040894,4.58578644 L19.4183029,6 L13.4162582,12.0020447 L19.4142136,18 L18,19.4142136 L12.0020447,13.4162582 L6,19.4183029 L4.58578644,18.0040894 L10.5878311,12.0020447 L4.58578644,6 L6,4.58578644 L12.0020447,10.5878311 Z"></path>
            </svg>
          </button>
        </div>
      `;

      return productBadgingContainer.insertAdjacentHTML('beforeend', markup)
    }

    // product badging close button
    component.closeProductBadge = () => {
      const productBadgingButton = document.querySelectorAll(component.config.selectors.productBadgingButton);
      productBadgingButton.forEach(event => {
        event.addEventListener('click', (e) => {
          event.parentNode.classList.remove('visible');
          event.parentNode.classList.add('close');
        });
      });
    }

    // Qubit tracking impression
    component.impression = (callbackData, impression) => {
      var observer = new IntersectionObserver(function (entries) {
        if (entries[0].isIntersecting === true) {
          if (component.gaClientId()) {
            component.impressionTracking(callbackData, siteObj.productID)
            observer.unobserve(impression)
          }
        }
      }, { threshold: [0.1] });

      observer.observe(impression);
    }

    component.impressionTracking = async (callbackData, productId) => {
      let params = new URLSearchParams({
        d: callbackData,
        t: "impression",
        ts: Date.now(),
        gcid: component.gaClientId(),
        debug: "qp"
      });

      let paramsWithId = new URLSearchParams({
        d: callbackData,
        t: "impression",
        ts: Date.now(),
        gcid: component.gaClientId(),
        p: productId,
        debug: "qp"
      });

      let promises = [
        fetch(component.config.urls.qubitTrackingUrl + params),
        fetch(component.config.urls.qubitTrackingUrl + paramsWithId)
      ];

      await Promise.allSettled(promises).then((responses) => {
        responses.forEach((response) => {
          if (response.ok) {
            console.log('success');
          }
        });
      }).catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
      });
    };

    // Get GA client ID
    component.gaClientId = () => {
      try {
        var tracker = ga.getAll()[0];
        return tracker.get('clientId');
      }
      catch (e) {
        return;
      }
    }

    //Get cookie consent
    component.getCookieConsent = () => {
      let bannerAcceptButton = document.getElementById('onetrust-accept-btn-handler');
      let pcAllowAllButton = document.getElementById('accept-recommended-btn-handler');
      let pcSaveButton = document.getElementsByClassName('save-preference-btn-handler onetrust-close-btn-handler')[0];

      if (!bannerAcceptButton && !pcAllowAllButton && !pcSaveButton) {
        attempts += 1;
        if (attempts > 30) {
          return;
        }
        setTimeout(component.getCookieConsent, 100);
      } else {
        if (bannerAcceptButton) {
          bannerAcceptButton.addEventListener('click', (e) => { component.init(); });
        }
        if (pcAllowAllButton) {
          pcAllowAllButton.addEventListener('click', (e) => { component.init(); });
        }
        if (pcSaveButton) {
          pcSaveButton.addEventListener('click', (e) => { component.init(); });
        }
        return;
      }
    }

    //Qubit tracker cookie
    component.setCookie = (cookieValue) => {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      const path = '/';
      let domain;
      if ($window.location.host.indexOf('www') === 0) {
        domain = siteUrl.replace('www', '');
      } else {
        domain = `.${siteUrl}`;
      }
      if (!cookie.get(qubitCookieName)) {
        document.cookie = `${qubitCookieName}=${cookieValue}; expires=${expirationDate.toUTCString()}; domain=${domain}; path=${path};`;
      }
    }

    return component;
  };

  return qubitProductPageBadging;
});
